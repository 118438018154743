import {useEffect, useState} from "react";
import {getConfig, PopUpAlert} from "../../controller/utils";
import {axiosInstance} from "../../utils/API";
import {Button, Col, Container, Form, Row, Spinner} from "react-bootstrap";
import StripePayment from "../StripePayment";

export default function UserPaymentMethods({headerTitle, onPaymentMethodsSelected, chosenPaymentMethod}) {
    const [formShown, showForm] = useState(false);
    const [loading, setLoading] = useState(false);
    const [paymentMethods, setPaymentMethods] = useState([]);

    const [pMethod, setPMethod] = useState(null);

    useEffect(() => {
        setLoading(true);
        getAllpaymentMethods().then((r) => {
            setPaymentMethods(r.data?.result);
            setLoading(false);
        })
    }, []);

    useEffect(() => {
        if (paymentMethods && paymentMethods.length > 0)
            onPaymentMethodsSelected(paymentMethods[0])


    }, [paymentMethods])

    useEffect(() => {
        if(pMethod){
            console.log('An ajax call should be made...')
            savePaymentMethod();
        }
    }, [pMethod])

    const getAllpaymentMethods = () => {
        const config = getConfig();
        return axiosInstance.get('stripe/getSavedCards', config)
    }

    const savePaymentMethod = () => {
        setLoading(true);
        const config = getConfig();

        const payload = {
            "paymentMethodId": pMethod.id
        }

        const axiosMethod = axiosInstance.post;

        return axiosMethod(`stripe/saveCard`, payload, config)
            .then((res) => {
                showForm(false);
                setLoading(true);
                getAllpaymentMethods().then((r) => {
                    setPaymentMethods(r.data?.result);
                    setLoading(false);
                })

            }).catch((err) => {
                if (err?.response?.status) {
                    alert(err.response?.data?.message);
                }
            }).finally((res) => {
                console.log(res);
                setLoading(false);
            });

    }

    const deletePaymentMethod = (e, paymentMethod) => {
        e.preventDefault();

        PopUpAlert("Alert!", "Are you sure you want to delete this payment method?", "warning", true).then(
            (err) => {
                if(err.isConfirmed){
                    setLoading(true);
                    const config = getConfig();

                    return axiosInstance.delete(`stripe/deleteCard/${paymentMethod.id}`, config)
                        .then((res) => {

                            setLoading(true);
                            getAllpaymentMethods().then((r) => {
                                setPaymentMethods(r.data?.result);
                                setLoading(false);
                            })

                        }).catch((err) => {
                            if (err?.response?.status) {
                                alert(err.response?.data?.message);
                            }
                        }).finally((res) => {
                            console.log(res);
                            setLoading(false);
                        });

                }
            }
        );
    }

    return (

        <div className={'paymentMethodForm'}>
            <h4 className={'text-white'}>{headerTitle}</h4>
            <div className={'p-2 text-white'}>
                {loading ? <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner> :
                paymentMethods.map((pm) => {
                    return <div className={'text-white my-3 d-flex flex-row align-items-center'}>
                        <input type={'radio'} checked={pm == chosenPaymentMethod} name={'paymentMethod'}
                               onClick={() => onPaymentMethodsSelected(pm)}/>
                        <div className={'ms-2'}>
                            <span><b>{pm.brand.toUpperCase()}</b></span><br/>
                            <span>* * * * {pm.last4} </span><br/>
                            <span style={{color: 'gray'}}><small>Expires {pm.expMonth}/{pm.expYear}</small></span>
                        </div>
                        <div className={'mx-2 my-0'}>
                            <Button className={'addDeliveryBtn'} onClick={(e) => {
                                    deletePaymentMethod(e, pm);
                            }}>Delete</Button>
                        </div>

                    </div>
                })
                }
                {!formShown && <Button className={'addDeliveryBtn d-block'} onClick={() => {
                    showForm(true)
                }}>Add new credit card</Button>}
            </div>

            {formShown &&
                <StripePayment disabled={false} onClose={() => {
                    showForm(false);
                }
                } setPaymentMethod={(sPaymentMethod) => {
                    showForm(false);
                    setPMethod(sPaymentMethod)
                }} />
            }
        </div>
    )
}
