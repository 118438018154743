import {useStripe} from "@stripe/react-stripe-js";
import {useEffect, useState} from "react";

export default function StripeConfirmation({clientSecret, paymentMethodId, onCardConfirmationFn}){
    const stripe = useStripe();

    useEffect(async () => {
        const {
            error,
            paymentIntent
        } = await stripe.confirmCardPayment(clientSecret, {
            payment_method: paymentMethodId
        });

        onCardConfirmationFn(error, paymentIntent);
    })

    return null
}
