import {CardElement, useStripe, useElements} from '@stripe/react-stripe-js';

import "./style.scss"
import {useEffect, useState} from "react";
import {Button} from "react-bootstrap";
import {isMobile} from "react-device-detect";

function StripePayment({disabled, setPaymentMethod, onClose}) {

    const [errorMessage, setErrorMessage] = useState("");

    const [loading, setLoading] = useState(false);

    const [billingFullName, setBillingFulName] = useState("");
    const [cardIsComplete, setCardAsCompleted] = useState(false);


    const stripe = useStripe();
    const elements = useElements();

    useEffect(() => {
        if(billingFullName && cardIsComplete){
            createPaymentMethod();
        }
    }, [billingFullName])

    const cardStyle = {
        hidePostalCode: true,
        style: {
            base: {
                color: "#EFEFEF",
                fontFamily: 'Arial, sans-serif',
                fontSmoothing: "antialiased",
                fontSize: isMobile ? "15px" : "18px",
                "::placeholder": {
                    color: "#EFEFEF"
                }
            },
            invalid: {
                fontFamily: 'Arial, sans-serif',
                color: "#fa755a",
                iconColor: "#fa755a"
            }
        }
    };


    const createPaymentMethod = () => {
        setLoading(true);

        stripe.createPaymentMethod({
            card: elements.getElement(CardElement),
            type: 'card',
            billing_details: {
                name: billingFullName
            }
        })
            .then(function (result) {
                if(result.error){
                    setErrorMessage(result.error.message);
                }else{
                    setErrorMessage("")
                    setPaymentMethod(result.paymentMethod);
                }
                setLoading(false);
            });
    }

    return (
        <>
            <input disabled={loading} type={'text'} value={billingFullName} onChange={(e) => {
                setBillingFulName(e.target.value);
            }} className={'form-control mb-3'} placeholder={'Full Name'} />
            <CardElement disabled={loading} options={cardStyle} className={'cardElement form-control'} onChange={(e) => {
                if (e.error) {
                    setErrorMessage(e.error.message);
                    setCardAsCompleted(false)
                }else{
                    setErrorMessage("")
                    setCardAsCompleted(true)
                }
            }}/>
            <div className={'text-danger'}>{errorMessage}</div>
             <div className={'d-flex justify-content-end mt-2'}>

                     <Button onClick={() => onClose()}>Cancel</Button>
                     <Button variant="primary" type="submit" className={'submit ms-2'}
                             disabled={loading} onClick={() => {
                         if(billingFullName)
                             createPaymentMethod();
                         else
                             setErrorMessage("Please fill your billing full name!")
                     }}>
                         {loading ? 'Saving...' : 'Save'}
                     </Button>
             </div>
        </>
    )

}

export default StripePayment
